import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import classNames from "classnames/bind";
import styles from "./PortfolioHeader.module.scss";

class PortfolioHeader extends React.Component {
    render() {
        const {
            frontmatter
        } = this.props;

        const cx = classNames.bind(styles);

        const classes = cx(
            "wrapper",
            frontmatter.theme,
        );

        return (
            <div className={classes}
                style={{backgroundColor: frontmatter.headerColor}}
            >
                <div className={styles.content}>
                    <div className={styles.copy}>
                        <p className={styles.breadcrumb}>
                            <span className={styles.part}>
                                <Link
                                    className={styles.link}
                                    to="/about/bryan"
                                >
                                    Bryan McDowall
                                </Link>
                            </span>
                            <span className={styles.part}>
                                /
                                <Link
                                    className={styles.link}
                                    to="/portfolios/1"
                                >
                                    Portfolio
                                </Link>
                            </span>
                        </p>
                        <h1 className={styles.title}>{frontmatter.title}</h1>
                        <p className={styles.description}>{frontmatter.excerpt}</p>
                        {frontmatter.urlLink && frontmatter.urlTitle &&
                            <a
                                href={frontmatter.urlLink}
                                className={styles.button}
                                target="_blank"
                                rel="noreferrer"
                            >
                                    {frontmatter.urlTitle}
                            </a>
                        }
                    </div>
                    {frontmatter.image &&
                        <GatsbyImage
                            image={frontmatter.headerImage.childImageSharp.gatsbyImageData}
                            className={styles.image}
                            alt={frontmatter.title}
                            title={frontmatter.title}
                        />
                    }
                </div>
            </div>
        );
    }
};

export default PortfolioHeader;