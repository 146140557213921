import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout/Layout";
import { Tags } from "../components/tags/Tags";
import PageHeader from "../components/page-header/PageHeader";
import PortfolioHeader from "../components/portfolio-header/PortfolioHeader";
import BodyHeader from "../components/body-header/BodyHeader";
import { Share } from "../components/share/Share";
import ScrollProgress from "../components/scroll-progress/ScrollProgress";
import Posts from "../components/posts/Posts";
import AffiliateLink from "../components/affiliate-link/AffiliateLink";

import classNames from "classnames/bind";
import styles from "./Post.module.scss";

export const query = graphql
    `query PostByID(
        $slug: String!,
        $type: String!,
        $cat: String!
    ) {
        site {
            siteMetadata {
                s3bucket
                siteUrl
            }
        }

        related: allMdx(
            limit: 5
            filter: {
                frontmatter: {
                    category: {eq: $cat},
                    type: {eq: $type}
                },
                slug: {ne: $slug}
            }
            sort: {
                fields: [frontmatter___date],
                order: DESC
            }
        ) {
            nodes {
                slug
                frontmatter {
                    category
                    date(formatString: "MMM Do, YYYY")
                    dateSimple: date(formatString: "YYYY-MM-DD")
                    released(formatString: "MMM Do, YYYY")
                    releasedSimple: released(formatString: "YYYY-MM-DD")
                    excerpt
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                width: 254,
                                quality: 80,
                                formats: [WEBP, JPG],
                                layout: CONSTRAINED
                            )
                        }
                    }
                    length
                    title
                    type
                    media
                }
            }
        }

        mdx(slug: {eq: $slug}) {
            body
            slug
            frontmatter {
                author {
                    friendlyName
                    page
                }
                image {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 80,
                            layout: FULL_WIDTH
                        )
                        fixed(
                            toFormat: JPG
                            cropFocus: ATTENTION
                            height: 800
                            width: 1200
                            jpegQuality: 100
                        ) {
                            src
                        }
                    }
                }
                title
                date(formatString: "MMM Do, YYYY")
                dateSimple: date(formatString: "YYYY-MM-DD HH:mm z")
                type
                category
                excerpt
                tags
                media
                productImage {
                    childImageSharp {
                        gatsbyImageData(
                            formats: [WEBP, JPG]
                            width: 172
                            quality: 100
                            layout: CONSTRAINED
                        )
                    }
                }
                productUrl
                headerImage {
                    childImageSharp {
                        gatsbyImageData(
                            height: 450,
                            quality: 100,
                            layout: FIXED
                        )
                    }
                }
                headerColor
                theme
                proficiencies
                client
                released(formatString: "MMM Do, YYYY")
                releasedSimple: released(formatString: "YYYY-MM-DD")
                urlTitle
                urlLink
            }
        }
    }
`

const Post = ({ data, location }) => {

    const { body, frontmatter } = data.mdx;

    const cx = classNames.bind(styles);

    const contentClasses = cx(
        "content",
        frontmatter.type
    );

    return (
        <Layout
            location={location}
            type={frontmatter.type}
            title={frontmatter.title}
        >
            <GatsbySeo
                openGraph={{
                    title: frontmatter.title,
                    description: frontmatter.excerpt,
                    url: location.href,
                    type: "article",
                    article: {
                        publishedTime: frontmatter.dateSimple,
                        section: frontmatter.category,
                        authors: [
                            data.site.siteMetadata.siteUrl + frontmatter.author.page
                        ],
                        tags: frontmatter.tags.map(tag => { return tag }),
                    },
                    images: [
                        {
                            url: data.site.siteMetadata.siteUrl + frontmatter.image.childImageSharp.fixed.src,
                            width: 1200,
                            height: 800,
                            alt: frontmatter.title,
                        },
                    ],
                }}
            />

            <ScrollProgress />

            {["post","review"].indexOf(frontmatter.type) >= 0 ?
                <PageHeader
                    image={frontmatter.image}
                    alt={frontmatter.title}
                />
            : frontmatter.type === "portfolio" ?
                <PortfolioHeader
                    frontmatter={frontmatter}
                />
            : null}

            <div className={contentClasses}>
                <div className={styles.primary}>
                    {["post","review"].indexOf(frontmatter.type) >= 0 &&
                        <BodyHeader
                            frontmatter={frontmatter}
                        />
                    }

                    {frontmatter.productUrl && frontmatter.type === "review" &&
                        <AffiliateLink
                            title={
                                `${frontmatter.title}${frontmatter.media && frontmatter.media.length > 0 &&
                                ` [${frontmatter.media}]`
                                }`
                            }
                            url={frontmatter.productUrl}
                            isFullsize={false}
                            className={styles.topAd}
                        />
                    }

                    <div className={styles.body}>
                        <MDXRenderer>{body}</MDXRenderer>
                    </div>

                    {frontmatter.productUrl && frontmatter.type === "review" &&
                        <AffiliateLink
                            title={
                                `${frontmatter.title}${frontmatter.media && frontmatter.media.length > 0 &&
                                ` [${frontmatter.media}]`
                                }`
                            }
                            url={frontmatter.productUrl}
                            image={frontmatter.productImage ? frontmatter.productImage.childImageSharp.gatsbyImageData : null}
                            className={styles.bottomAd}
                        />
                    }
                </div>

                <div className={styles.secondary}>
                    {frontmatter.tags && frontmatter.tags.length > 0 &&
                        <Tags
                            className={styles.tags}
                            title={"Tags"}
                            tags={frontmatter.tags}
                        />
                    }

                    <Share
                        className={styles.share}
                        title={`Share this ${frontmatter.type}`}
                        currentUrl={location.href}
                        pageTitle={frontmatter.title}
                    />

                    {data.related.nodes.length > 0 &&
                        <div className={styles.related}>
                            <h3 className={styles.subTitle}>Related {frontmatter.type}s</h3>
                            <Posts
                                hideCat
                                data={data.related}
                            />
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
}

export default Post;